import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 3rem;
`;

export const ExternalLinkContainer = styled.div`
  padding: 0.5rem 0;
`;

export const Heading = styled.h1`
  text-decoration: none;
`;

export const LinkHeading = styled.h3`
  display: inline;
  font-weight: 400;
  font-size: 0.9rem;
  margin-right: 0.5rem;
`;

export const Excerpt = styled.p`
  ${ExternalLinkContainer} & {
    display: inline;
    font-size: 0.9rem;
    opacity: 0.5;
  }
`;
