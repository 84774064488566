import styled from 'styled-components'

const lineColor = `#888`

export const Container = styled.div`
	width: 70%;
	margin: 2rem 15%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	position: relative;
`

export const LineHeading = styled.div`
	opacity: 0.5;
	width: 70%;
	margin: 1rem 15%;
	text-align: center;
	color: ${lineColor};
	position: relative;

	&::before {
		content: '';
		position: absolute;
		border-bottom: 1px solid ${lineColor};
		width: 100%;
		height: calc(50% + 1px);
		top: 0;
		left: 0;
		z-index: 0;
	}

	span {
		display: inline-block;
		background-color: white;
		z-index: 1;
		padding: 0 0.5rem;
		position: relative;
	}
`
