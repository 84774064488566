import React from 'react'
import { Container, LineHeading } from './styles'

export default function LinkSet ({ children }) {
	return (
		<Container>
			<LineHeading><span role="img" aria-label="Link">🔗</span></LineHeading>

			{children}

			<LineHeading><span role="img" aria-label="Link">🔗</span></LineHeading>
		</Container>
	)
}
