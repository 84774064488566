import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContentContainer from '../components/ContentContainer'
import PostLink from '../components/PostLink/index'
import HeroSvg from '../components/HeroSvg/index'
import SvgComponent from '../images/perfectoffice.svg'
import isUrlAbsolute from '../utils/isUrlAbsolute'
import LinkSet from '../components/LinkSet/index'

function IndexPage ({
	data: {
		allMarkdownRemark: { edges }
	}
}) {
	const postData = edges.reduce((list, edge) => {
		const isExternalPost = isUrlAbsolute(edge.node.frontmatter.path)

		if (!isExternalPost) {
			if (list.length && Array.isArray(list[0])) {
				// the first index is a list of links.
				// we should always lead with an actual post.
				return [edge, ...list]
			}

			return [...list, edge]
		}

		const lastIdx = list.length - 1

		if (list[lastIdx] && Array.isArray(list[lastIdx])) {
			return list
				.slice(0, lastIdx)
				.concat([[...list[lastIdx], edge]])
		}

		return [...list, [edge]]
	}, [])

	const Posts = postData.map((edge) => {
		if (Array.isArray(edge)) {
			// set of links
			return (
				<LinkSet key={edge[0].node.id}>
					{edge.map((actualEdge, idx) => (
						<PostLink key={`${actualEdge.node.id}-${idx}`} post={actualEdge.node} />
					))}
				</LinkSet>
			)
		}

		return <PostLink key={edge.node.id} post={edge.node} />
	})

	return (
		<Layout>
			<SEO />

			<HeroSvg>
				<SvgComponent />
			</HeroSvg>

			<ContentContainer>
				{Posts}
			</ContentContainer>
		</Layout>
	)
}

export default IndexPage

export const pageQuery = graphql`
	query {
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					id
					excerpt(pruneLength: 250)
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						path
						title
					}
				}
			}
		}
	}
`
