import React from 'react'
import { Link } from 'gatsby'
import { Container, ExternalLinkContainer, Heading, LinkHeading, Excerpt } from './styles'
import isUrlAbsolute from '../../utils/isUrlAbsolute'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

export default function PostLink ({ post }) {
	const isExternalLink = isUrlAbsolute(post.frontmatter.path)

	if (isExternalLink) {
		return (
			<ExternalLinkContainer>
				<OutboundLink
					href={post.frontmatter.path}
					target="_blank"
					rel="noopener noreferrer"
				>	
					<LinkHeading>{post.frontmatter.title}</LinkHeading>
				</OutboundLink>

				<Excerpt>{post.excerpt}</Excerpt>
			</ExternalLinkContainer>
		)
	}

	return (
		<Container>
			<Link to={post.frontmatter.path}>
				<Heading>{post.frontmatter.title}</Heading>
			</Link>

			<Excerpt>{post.excerpt}</Excerpt>
		</Container>
	)
}
